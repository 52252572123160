<template>


  <div :style="{'background-color': sfondo_3, 'color':testo_sfondo_3}">

    <!-- Sezione Mappa 100% -->
    <div
        v-if="geolocation.length > 0"
        id="dettaglio-manifesto-map"
        class="maps d-none d-md-block">
      <l-map
          style="height: 300px; width:100%;"
          :zoom="zoom"
          :center="getNormalizedGeolocation(geolocation)"
          gestureHandling
          :options="mapOptions"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="getNormalizedGeolocation(geolocation)"></l-marker>
      </l-map>
    </div>

    <div v-if="geolocation.length === 0">
      <br>
      <br>
    </div>
    <!-- /Sezione Mappa 100% -->
    <!-- Sezione Manifesto -->
    <div class="manifesto-dettaglio">
      <img class="photo-manifesto-dettaglio-vega"
           v-bind:src="foto_manifesto"
           alt="">
    </div>
    <!-- /Sezione Manifesto -->
    <!-- Sezione Info Defunto Mobile -->
    <div class="shop-mobile" v-if="tipologia === 'Lutto'" :style="{'background-color': sfondo_1 }">
      <h5 :style="{'color': testo_sfondo_1}">Invia un pensiero alla famiglia</h5>
      <div v-if="texture" class="texture-shop-mobile">
        <img :src="texture" alt="">
      </div>
      <div class="container-shop">
        <div class="first-shop-mobile" v-if="telegramma">
          <a :href="showService('telegramma')">
            <IconaTelegramma :style="{'fill': testo_sfondo_1}"
                             class="logo-services-dettaglio-manifesto"></IconaTelegramma>
            <p class="shop-mobile-text" :style="{'color':testo_sfondo_1}">Telegramma</p>
          </a>
        </div>

        <div class="second-shop-mobile" v-if="fiori">
          <a :href="showService('fiori')">
            <IconaFiori :style="{'fill': testo_sfondo_1}" class="logo-services-dettaglio-manifesto"></IconaFiori>
            <p class="shop-mobile-text" :style="{'color':testo_sfondo_1}">Fiori</p>
          </a>
        </div>

        <div class="third-shop-mobile" v-if="pasto">
          <a :href="showService('pasto')">
            <IconaPasto :style="{'fill': testo_sfondo_1}" class="logo-services-dettaglio-manifesto"></IconaPasto>
            <p class="shop-mobile-text" :style="{'color':testo_sfondo_1}">Pasto</p>
          </a>
        </div>
      </div>
    </div>


    <div class="container-80 detail-defunto">
      <div class="info-defunto-section-manifesto">
        <div class="nome-eta">
          <span class="nome-section-manifesto" :style="{'color':testo_sfondo_3}">{{ nome_defunto }}</span><br>
          <span class="age" :style="{'color':testo_sfondo_3}"
                v-if="calculateYears(dettaglio_necrologio.data_nascita, dettaglio_necrologio.data_morte) > 0">{{
              calculateYears(dettaglio_necrologio.data_nascita, dettaglio_necrologio.data_morte)
            }} anni</span>
        </div>
        <div v-if="camera_ardente !== null " class="camera-ardente">
          <span :style="{'color':testo_sfondo_3, 'opacity': 0.4}">Camera ardente:</span><br>
          <span class="nome-camera" :style="{'color':testo_sfondo_3}">{{ camera_ardente }}</span>
        </div>
        <div v-if="data_rito_funebre !== null " class="rito-funebre">
          <span :style="{'color':testo_sfondo_3, 'opacity': 0.4}">Data rito funebre:</span><br>
          <span class="giorno-rito" :style="{'color':testo_sfondo_3}">{{
              dataRitorFunebreRappresentation(data_rito_funebre)
            }}</span>
        </div>
        <div v-if="luogo_rito_funebre !== null" class="luogo-rito">
          <span :style="{'color':testo_sfondo_3, 'opacity': 0.4}">Luogo rito funebre:</span><br>
          <span class="posto-rito" :style="{'color':testo_sfondo_3}">{{ luogo_rito_funebre }}</span>
        </div>
        <div class="indicazioni-stradali" v-if="tipologia !== 'Partecipazione'">
          <a :href=linkgooglemaps>
            <IndicazioniStradali :style="{'fill': sfondo_1}"
                                 class="logo-services-dettaglio-manifesto"></IndicazioniStradali>
          </a>
        </div>
      </div>

      <div class="shop" v-if="tipologia === 'Lutto'">
        <div class="first-shop">
          <a :href="showService('telegramma')" v-if="telegramma">
            <IconaTelegramma :style="{'fill': sfondo_1}"
                             class="logo-services-dettaglio-manifesto"></IconaTelegramma>
            <p :style="{'color':testo_sfondo_3}">Invia Telegramma</p>
          </a>
        </div>
        <div class="second-shop">
          <a :href="showService('fiori')" v-if="fiori">
            <IconaFiori :style="{'fill': sfondo_1}" class="logo-services-dettaglio-manifesto"></IconaFiori>
            <p :style="{'color':testo_sfondo_3}">Invia Fiori</p>
          </a>
          <a :href="showService('pasto')" v-if="pasto">
            <IconaPasto :style="{'fill': sfondo_1}" class="logo-services-dettaglio-manifesto"></IconaPasto>
            <p :style="{'color':testo_sfondo_3}">Invia Pasto</p>
          </a>
        </div>
      </div>
    </div>

    <!-- /Sezione Info Defunto  -->
    <div class="container-80" v-if="necrologioId !== null">
      <hr :style="{'background-color': sfondo_1}">
    </div>

    <!-- other-info -->
    <div class="container-80 other-info-manifesto" v-if="necrologioId !== null">
      <div class="container-info">
        <div class="photo">
          <img
              class="img-defunto-vega"
              :style="{'border': '5px solid' + sfondo_1}"
              v-bind:src="getPhotoNecrologioFromCdn(dettaglio_necrologio)"
              alt="">
        </div>
        <div class="section-info-personali-manifesto">
          <div class="nome-eta">
            <span class="nome" :style="{'color':testo_sfondo_3}">{{ nome_defunto }}</span><br>
            <span class="age" :style="{'color':testo_sfondo_3}">{{
                calculateYears(dettaglio_necrologio.data_nascita, dettaglio_necrologio.data_morte)
              }} anni</span>
          </div>
          <div class="address-city">
            <span :style="{'color':testo_sfondo_3, 'opacity': 0.4}">{{ indirizzo }}</span>
          </div>
        </div>
      </div>

      <router-link :to="{name: 'DettaglioNecrologio', params: {id: necrologioId}}">
        <div class="visualizza-pagina-vega" :style="{'background-color': sfondo_1 , 'color':testo_sfondo_1}">
          <span>Visualizza</span>
          <span>la sua Pagina</span>
        </div>
      </router-link>

    </div>
    <!-- /other-info -->
  </div>
</template>


<script>
import {GestureHandling} from "leaflet-gesture-handling";
import IconaFiori from '@/assets/TemplateImg/Template_altair/icon_flower.svg?inline'
import IconaPasto from '@/assets/TemplateImg/Template_altair/icon_dish.svg?inline'
import IconaTelegramma from '@/assets/TemplateImg/Template_altair/icon_telegram.svg?inline'
import IndicazioniStradali from '@/assets/TemplateImg/Template_altair/icon_street.svg?inline'
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import {mapGetters} from "vuex";
import {
  yearsCalculate,
  normalizeDataRitoFunebre,
  photoNecrologioFromCDN,
} from "@/utils/util";

export default {
  name: "DettaglioManifesto",
  computed: {
    ...mapGetters([
      'telegramma',
      'fiori',
      'partecipazione',
      'pasto',
      'texture',
      'slug',
      'sfondo_3',
      'sfondo_1',
      'sfondo_2',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'geolocation',
    ]),
  },

  components: {
    LMap,
    LTileLayer,
    LMarker,
    IconaFiori,
    IconaPasto,
    IconaTelegramma,
    IndicazioniStradali,
    // eslint-disable-next-line vue/no-unused-components
    GestureHandling
  },

  data() {

    return {
      manifestoId: null,
      necrologioId: null,
      dettaglio_necrologio: [],
      dettaglio_manifesti: [],
      geolocation: [],
      foto_manifesto: null,
      nome_defunto: null,
      anni: null,
      latitudine: null,
      longitudine: null,
      linkgooglemaps: null,
      indirizzo: null,
      tipologia: null,
      camera_ardente: null,
      data_rito_funebre: null,
      luogo_rito_funebre: null,
      via_defunto: null,
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      mapOptions: {
        zoomSnap: 0.5,
        gestureHandling: true
      }
    }
  },

  metaInfo() {
    return {
      title: 'Manifesto - ' + this.nome_defunto,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo
        },
        {property: 'og:title', content: ' - Epiloge'},
        {property: 'og:site_name', content: 'Epiloge'},
        {
          property: 'og:description',
          content: 'Connect and follow on Epiloge - '
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  methods: {

    showService(type) {
      return window.href = 'https://www.funer24.com/servizio/' + this.slug + "/" + type + "/" + this.manifestoId + "/"
    },

    photoManifestoFromCDN(item) {
      if (item.foto !== null) {
        return "https://funer24-cdn.com/" + item.foto.foto_home_necrologi_desktop
      } else {
        return item.foto_manifesto;
      }
    },


    dataRitorFunebreRappresentation(data_rito_funebre) {
      return normalizeDataRitoFunebre(data_rito_funebre)
    },

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    getPhotoNecrologioFromCdn(dettaglio_necrologio) {
      return photoNecrologioFromCDN(dettaglio_necrologio)
    },


    getDettaglioNecrologio(necrologioId) {
      // eslint-disable-next-line no-undef
      axios
          .get(this.$API_DETTAGLIO_NECROLOGIO + necrologioId)
          .then((response) => {
            console.log('DATI RICEVUTI NECROLOGIO', response);
            if (response.status === 200) {
              console.log(response.data)
              this.dettaglio_necrologio = response.data;
              this.nome_defunto = this.dettaglio_necrologio.nome;
              this.data_morte = this.dettaglio_necrologio.data_morte;
              this.data_nascita = this.dettaglio_necrologio.data_nascita;
              this.cimitero = this.dettaglio_necrologio.cimitero;
              this.indirizzo = this.dettaglio_necrologio.address;
              this.geolocation = this.dettaglio_necrologio.geolocation;
            }
          }, (error) => {
            console.log('Errore', error);
          });
    },

    getNormalizedGeolocation(geolocation) {
      if (geolocation !== undefined) {
        let geolocationArray = geolocation.split(",")
        // const longitude = parseFloat(geolocationArray[0])
        // const latitude = parseFloat(geolocationArray[1])
        this.latitudine = parseFloat(geolocationArray[1])
        this.longitudine = parseFloat(geolocationArray[0])
        this.linkgooglemaps = `https://www.google.de/maps?q=${this.longitudine},${this.latitudine}&amp;z=20&amp;t=k`
        return [this.longitudine, this.latitudine];
      }
    },

    getDettaglioManifesto() {
      // eslint-disable-next-line no-undef
      axios
          .get(this.$API_DETTAGLIO_MANIFESTO + this.manifestoId)
          .then((response) => {
            console.log('Dettaglio', response);
            if (response.status === 200) {
              console.log(response.data)
              this.dettaglio_manifesti = response.data;
              this.nome_defunto = this.dettaglio_manifesti.nome_manifesto;
              this.tipologia = this.dettaglio_manifesti.tipologia;
              this.camera_ardente = this.dettaglio_manifesti.camera_ardente;
              this.data_rito_funebre = this.dettaglio_manifesti.giorno_ora_messa;
              this.luogo_rito_funebre = this.dettaglio_manifesti.nome_chiesa;
              this.foto_manifesto = this.photoManifestoFromCDN(this.dettaglio_manifesti);
              if (this.dettaglio_manifesti.necrologi !== undefined) {
                this.necrologioId = this.dettaglio_manifesti.necrologi.id;
                this.getDettaglioNecrologio(this.necrologioId)
              }
            }
          }, (error) => {
            console.log(error);
          });
    },

    props: {
      id: String,
    },
  },

  created() {
    this.manifestoId = this.$router.history.current.params.id;
    this.getDettaglioManifesto();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>


<style scoped>
@import '~@/assets/css/template_vega/dettaglio_manifesto.css';
</style>
